import { Button, CheckboxInput, Price, TableList, TableListCell, TableListRow } from '@brainfinance/icash-component-library';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { postMessageSender } from '../../../Helpers/PostMessage';
import { goToView, REVIEW_DOCUMENTS_PATH } from '../../../Helpers/Routes';
import { DashboardsContext } from '../../../Reducers/Dashboard';
import Summary, { SummaryMobile } from '../../Summary';
import CountdownContractExpire from '../../Utilities/CountdownContractExpire';
import './style.css';
import backMenu from '../../../images/chevron-left.svg';
import info from '../../../images/info.svg';
import ResizeObserver from 'resize-observer-polyfill';

function LoanAgreementReady() {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(DashboardsContext);
  const [waitingContractFetch, setWaitingContractFetch] = React.useState(false);
  const iframeRef = React.useRef<HTMLDivElement | null>(null);
  const [errorEnable, setErrorEnable] = React.useState(false)

  React.useEffect(() => {
    if (!iframeRef.current) return;
    const resizeObserver = new ResizeObserver(event => {
      event.forEach(item => {
        const height = item.contentRect.height + 44 + 44;

        postMessageSender({
          status: 'size',
          data: {
            height: height,
          },
        });
      });
    });

    resizeObserver.observe(iframeRef.current);
    return () => {
      resizeObserver.disconnect();
    }; // clean up
  }, [iframeRef]);


  const onGoNext = React.useCallback(() => {
    postMessageSender({
      status: 'marketing',
      payload: {
        status: 'track',
        data: {
          event: 'sign_contract_payment_plan_accepted',
        },
      },
    });

    goToView(navigate, REVIEW_DOCUMENTS_PATH);
  }, [navigate]);

  React.useEffect(() => {
    if (state.loanContract && waitingContractFetch && !errorEnable) {
      onGoNext();
    }
  }, [state, waitingContractFetch, onGoNext, errorEnable])

  const onAcceptAndContinue = async () => {

    const repaymentScheduleItems = state.repaymentScheduleItems ?? []

    for (let i = 0; i < repaymentScheduleItems.length; i++) {
      const repaymentScheduleItem = repaymentScheduleItems[i]

      if (!repaymentScheduleItem.checked) {
        postMessageSender({
          status: 'marketing',
          payload: {
            status: 'track',
            data: {
              event: 'dashboard_repayments_validation_error',
            },
          },
        });
        setErrorEnable(true)
        return;
      }
    }
    setErrorEnable(false)

    if (!state.loanContract) {
      setWaitingContractFetch(true);
      return;
    }
   
    onGoNext();
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    postMessageSender({
      status: 'marketing',
      payload: {
        status: 'screen',
        data: {
          categoryName: 'Dashboard',
          pathName: 'sign_contract_payment_plan_loaded',
        },
      },
    });
  }, []);

  React.useEffect(() => {
    if (errorEnable) {
      let checkboxChecked = true
        const repaymentScheduleItems = state.repaymentScheduleItems ?? []
        for (let i = 0; i < repaymentScheduleItems.length; i++) {
          const item = repaymentScheduleItems[i]
          if (item.checked === false) {
            checkboxChecked = false
          }
        }
        if (checkboxChecked) {
          setErrorEnable(false)
        }
    }
  }, [state.repaymentScheduleItems, errorEnable])

  return (
    <div ref={iframeRef} className="">
      <h1 className="main-title-caption mb-[30px] sm:hidden">Congratulations! Your loan agreement is ready!</h1>
      <div className="hidden sm:sticky sm:top-0 bg-[#FFFFFF] sm:mb-[11px] sm:grid grid-cols-12 items-center py-[11px] z-[20]">
        <div className="col-span-1 p-[8px]" onClick={() => postMessageSender({ status: 'return' })}>
          <img alt="back" className="col-span-1" src={backMenu} rel="preload" />
        </div>
        <h2 className="title-caption !text-[18px] text-center col-span-10">
          Congrats! Your
          <br />
          agreement is ready!
        </h2>
        <div className="flex justify-end col-span-1 p-[8px]">
          <img
            alt="contact-us"
            src={info}
            rel="preload"
            onClick={() => postMessageSender({ status: 'contact-us', data: { message: 'Contact us mobile' } })}
          />
        </div>
      </div>
      <div className="flex flex-row sm:px-[8px]">
        <div id="content" className="w-[486px] sm:w-[100%] flex flex-col">
          <div>
            <p className="caption-light">
              Please take a moment to read and understand the repayment schedule of your loan agreement.
            </p>
          </div>
          <div id="right" className="hidden sm:block ml-[90px] sm:ml-[0px] sm:mt-[22px]">
            <SummaryMobile
              loanDetails={state.loanDetails ?? 0}
              repayments={state.repayments ?? 0}
              totalAmountOfPayments={state.totalAmountOfPayments ?? 0}
            />
          </div>
          <div className="mt-[22px]">
            <div className="flex justify-between max-w-[650px] mx-auto flex-wrap sm:flex-col">
              <TableList title="Repayment schedule">
                {
                  errorEnable ? <td colSpan={3}>
                    <div className='bg-[#FFE8EC] p-[16px] rounded-[8px]'><p className='caption-medium !text-[#13141A]'>
                    All repayment dates must be confirmed by selecting the corresponding checkboxes.</p></div>
                  </td> : null
                }
                {(state.repaymentScheduleItems ?? []).map((item, index) => (
                  <>
                  <TableListRow key={index} className={index === (state.repaymentScheduleItems?.length ?? 0) - 1 && state.changeableLastDate === true ? "!border-[0px] !bg-[#FFCB3D4D] !rounded-t-[8px]" : ""}>
                    {/* <TableListCell className='flex items-center'></TableListCell> */}
                    <TableListCell>
                      <div className='flex'>
                      <CheckboxInput value={item.checked} className='!m-0' error={errorEnable && !item.checked ? ' ' : undefined} onChange={(value) => {
                        dispatch({type: 'checkedPayment', payload: {
                          index: index,
                          value: value ?? false
                        }})
                      }}>
                      <p className={`subtitle-medium mt-[4px] sm:hidden ${errorEnable && !item.checked ? "text-[#FF5368]" : ""}`}>{item.title}</p>
                      <p className={`subtitle-medium mt-[4px] hidden sm:block ${errorEnable && !item.checked ? "text-[#FF5368]" : ""}`}>
                      {new Intl.DateTimeFormat('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                      }).format(item.date)}
                      </p>
                      </CheckboxInput>
                      </div>
                    </TableListCell>
                    <TableListCell className='sm:hidden !px-0'>
                      <p className={`subtitle-medium mt-[4px] ${errorEnable && !item.checked ? "text-[#FF5368]" : ""}`}>
                      {new Intl.DateTimeFormat('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                      }).format(item.date)}
                      </p>
                    </TableListCell>
                    <TableListCell>
                        <Price size="xsmall" amount={item.amount} className='mt-[4px]' colorClass={errorEnable && !item.checked ? `!text-[#FF5368]` : ""} />
                    </TableListCell>
                  </TableListRow>
                  {
                    index === (state.repaymentScheduleItems?.length ?? 0) - 1 && state.changeableLastDate === true ? <td colSpan={3} className='!bg-[#FFCB3D4D] px-4 pb-4 rounded-b-[8px]'>
                      <p className='caption-medium'>⚠️ To comply with provincial 62-day term loan regulations, your final loan payment may not align with your pay date. But no worries! Right after you sign your agreement, we’ll offer you the option to push your final payment to your next pay date.</p>
                    </td> : null
                  }
                  </>
                ))}
                <TableListRow>
                <td colSpan={3}>
                  <p className='caption-medium  mt-[16px]'>*You must confirm your repayment date(s) by checking the box(es)</p>
                </td>
                </TableListRow>
              </TableList>
            </div>
          </div>
          {!state.changeableLastDate ? <p className="caption-light mb-[16px]">
            If the repayment dates are incorrect, contact us at{' '}
            <a href='mailto:application@icash.ca' title='application@icash.ca' className="text-primary cursor-pointer">
              application@icash.ca
            </a>{' '}
            to request a modification before signing your loan agreement.
            <br />
            Once accepted, we won't be able to modify your repayment dates.
          </p> : null}
          <CountdownContractExpire date={state.countdownContractExpireDate ?? new Date()} />
          <div className="mt-6"></div>
          <Button
            disabled={waitingContractFetch}
            loading={waitingContractFetch}
            appearance="primary"
            size="large"
            className="w-[100%]"
            onClick={onAcceptAndContinue}
          >
            Accept and continue
          </Button>
        </div>
        <div id="right" className="ml-[90px] sm:ml-[0px] sm:hidden">
          <Summary
            loanDetails={state.loanDetails ?? 0}
            repayments={state.repayments ?? 0}
            totalAmountOfPayments={state.totalAmountOfPayments ?? 0}
          />
        </div>
      </div>
    </div>
  );
}

export default LoanAgreementReady;
