import React from "react";
import { postMessageSender } from "../../../Helpers/PostMessage";
import backMenu from '../../../images/chevron-left.svg';
import info from '../../../images/info.svg';
import { AlertBanner, Button, Modal } from "@brainfinance/icash-component-library";
import Summary from "../../Summary";
import { DashboardsContext } from "../../../Reducers/Dashboard";
import { changeDate } from "../../../Helpers/Requests";

export default function AjustYourFinalPaymentDate() {
    const iframeRef = React.useRef<HTMLDivElement | null>(null);
    const { state } = React.useContext(DashboardsContext);
    const [skipPaymentDateShow, setSkipPaymentDateShow] = React.useState(false)
    const [paymentDateSuccessfulShow, setPaymentDateSuccessfulShow] = React.useState(false)
    const [error, setError] = React.useState<string>();
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
      postMessageSender({
        status: 'marketing',
        payload: {
          status: 'screen',
          data: {
            categoryName: 'Dashboard',
            pathName: 'adjust_final_paydate_loaded',
          },
        },
      });
    }, []);

    React.useEffect(() => {
        if (!iframeRef.current) return;
        const resizeObserver = new ResizeObserver(event => {
          event.forEach(item => {
            const height = item.contentRect.height + 44 + 44;
    
            postMessageSender({
              status: 'size',
              data: {
                height: height,
              },
            });
          });
        });
    
        resizeObserver.observe(iframeRef.current);
        return () => {
          resizeObserver.disconnect();
        }; // clean up
      }, [iframeRef]);
      
      const onConfirmNewDate = async () => {
        setSkipPaymentDateShow(false)
        setError(undefined)
        setLoading(true)

        postMessageSender({
          status: 'marketing',
          payload: {
            status: 'track',
            data: {
              event: 'adjust_final_paydate_clicked',
            },
          },
        });

        const response = await changeDate(state.token, state.loanId);

        if (response.success) {
          postMessageSender({
            status: 'marketing',
            payload: {
              status: 'track',
              data: {
                event: 'adjust_final_paydate_completed',
              },
            },
          });

          setLoading(false)
          setPaymentDateSuccessfulShow(true)
        } else {
          postMessageSender({
            status: 'marketing',
            payload: {
              status: 'track',
              data: {
                event: 'adjust_final_paydate_error',
                properties:{
                  error: response.message
                }
              },
            },
          });
          setError(response.message)
          setLoading(false)
        }

      }

      const onKeepOriginalDate = () => {
        setSkipPaymentDateShow(true)
      }

      const onCloseWithSuccessfulChange = () => {
        if (state.signDetails) {
          postMessageSender({ status: 'success', data: state.signDetails });
        } else {
          postMessageSender({ status: 'success', data: {contractId: 0, padId: 0} });
        }
      }

    return <div ref={iframeRef}>
        <h1 className="main-title-caption mb-[30px] sm:hidden">Adjust your final payment date</h1>
      <div className="hidden sm:sticky sm:top-0 bg-[#FFFFFF] sm:mb-[11px] sm:grid grid-cols-12 items-center py-[11px] z-[20]">
        <div className="col-span-1 p-[8px]" onClick={() => postMessageSender({ status: 'return' })}>
          <img alt="back" className="col-span-1" src={backMenu} rel="preload" />
        </div>
        <h2 className="title-caption !text-[18px] text-center col-span-10">
        Adjust your final
          <br />
          payment date
        </h2>
        <div className="flex justify-end col-span-1 p-[8px]">
          <img
            alt="contact-us"
            src={info}
            rel="preload"
            onClick={() => postMessageSender({ status: 'contact-us', data: { message: 'Contact us mobile' } })}
          />
        </div>
      </div>
      <div className="flex">
      <div className="flex gap-[32px] flex-col w-[486px] sm:w-[100%]">
        <p className="caption-light-no-color text-[#13141A]">Your final payment is not scheduled on a regular pay date. Please review the suggested adjustment below and confirm the change.</p>
        <AlertBanner status="info" className="w-full">
            <h3 className="text-[18px] leading-[22px] tracking-[-0.3px] text-[#13141A] font-500 mb-[24px]">Your updated payment information</h3>
            <div className="grid grid-cols-2 gap-[24px]">
                {state.paymentDate?.dueDate ? <div>
                    <p className="caption-medium mb-[8px]">Current date</p>
                    <p className="subtitle-medium-no-color text-[#13141A]">{new Intl.DateTimeFormat('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                      }).format(new Date(state.paymentDate.dueDate + 'T00:00:00'))}</p>
                </div> : null}
                {state.paymentDate?.newDueDate ? <div>
                    <p className="caption-medium mb-[8px]">New date</p>
                    <p className="subtitle-medium-no-color text-[#13141A]">{new Intl.DateTimeFormat('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                      }).format(new Date(state.paymentDate.newDueDate + 'T00:00:00'))}</p>
                </div> : null}
                <div>
                    <p className="caption-medium mb-[8px]">Current amount</p>
                    <p className="subtitle-medium-no-color text-[#13141A]">${state.paymentDate?.amount?.toFixed(2)}</p>
                </div>
                <div>
                    <p className="caption-medium mb-[8px]">New amount</p>
                    <p className="subtitle-medium-no-color text-[#13141A]">${state.paymentDate?.newAmount?.toFixed(2)}</p>
                </div>
            </div>
        </AlertBanner>
        <p className="caption-light-no-color text-[#13141A]">Need help? Contact us at <a href='mailto:application@icash.ca' title='application@icash.ca' className="text-primary cursor-pointer">
              application@icash.ca
            </a>.</p>
        <p className="caption">By clicking 'Confirm new date', you agree to the new payment date and any associated fees.</p>
        <div className="grid gap-[32px] grid-cols-2 sm:grid-cols-1 sm:gap-[16px]">
            <Button size="large" disabled={loading} onClick={onKeepOriginalDate} appearance="secondary">Keep original date</Button>
            <Button size="large" disabled={loading} loading={loading} onClick={onConfirmNewDate} appearance="primary">Confirm new date</Button>
        </div>
        {
          error ? <p className="caption !text-red-600">{error}</p> : null
        }
      </div>
      <div id="right" className="ml-[90px] sm:ml-[0px] sm:hidden">
          <Summary
            loanDetails={state.loanDetails ?? 0}
            repayments={state.repayments ?? 0}
            totalAmountOfPayments={state.totalAmountOfPayments ?? 0}
          />
        </div>
      </div>
      {skipPaymentDateShow && (
        <Modal onClose={() => setSkipPaymentDateShow(false)} className="!min-h-fit sm:!max-w-[90%]">
          <div className="modal--content pt-[44px] max-w-[576px]">
            <p className="title-caption">Skip payment date adjustment</p>
            <p className="caption-light mt-[24px]">Your final payment date has not been updated yet. You can adjust it in your Member Dashboard once the loan funds have been disbursed and before the final payment is initiated.</p>
            <div className="grid grid-cols-2 gap-[16px] sm:grid-cols-1 mt-[24px]">
              <Button
                appearance="secondary"
                size="large"
                onClick={() => {
                  setSkipPaymentDateShow(false)
                }}
              >
                Close
              </Button>
              <Button
                appearance="primary"
                size="large"
                onClick={() => {
                  postMessageSender({
                    status: 'marketing',
                    payload: {
                      status: 'track',
                      data: {
                        event: 'adjust_final_paydate_skip_submitted',
                      },
                    },
                  });

                  postMessageSender({
                    status: 'marketing',
                    payload: {
                      status: 'track',
                      data: {
                        event: 'adjust_final_paydate_skip_completed',
                      },
                    },
                  });

                  if (state.signDetails) {
                    postMessageSender({ status: 'success', data: state.signDetails });
                  } else {
                    postMessageSender({ status: 'success', data: {contractId: 0, padId: 0} });
                  }
                }}
              >
                Skip
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {paymentDateSuccessfulShow && (
        <Modal onClose={onCloseWithSuccessfulChange} className="!min-h-fit sm:!max-w-[90%]">
          <div className="modal--content pt-[44px] max-w-[576px]">
            <p className="title-caption">Payment date adjustment successful</p>
            <p className="caption-light mt-[24px]">Your final payment date was successfully adjusted. The changes have been applied to your payment schedule.</p>
            <div className="mt-[24px] w-full">
              <Button
                appearance="primary"
                size="large"
                className="w-full"
                onClick={() => {
                  onCloseWithSuccessfulChange();
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
}