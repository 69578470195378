import { Button, CheckboxInput, SignatureInput } from '@brainfinance/icash-component-library';
import * as React from 'react';
import { postMessageSender } from '../../../Helpers/PostMessage';
import { getPaymentDate, sign } from '../../../Helpers/Requests';
import { DashboardsContext } from '../../../Reducers/Dashboard';
import DocumentsView from '../../Utilities/DocumentsView';
import { PDFViewer } from '../../Utilities/PdfViewer';
import './style.css';
import backMenu from '../../../images/chevron-left.svg';
import info from '../../../images/info.svg';
import { useNavigate } from 'react-router-dom';
import { AJUST_YOUR_FINAL_PAYMENT_DATE, goToView } from '../../../Helpers/Routes';
import ResizeObserver from 'resize-observer-polyfill';

const configStatusSignature = {
  empty: {
    classname: "text-interface-300",
    message: "Signature required."
  },
  weak: {
    classname: "text-red-500 !bottom-[2%]",
    message: (
      <span>
        Signature too small. <br />
        Please make sure it clearly reflects your full name and is large enough to be legible.
      </span>
    )
  },
  strong: {
    classname: "text-green-500",
    message: "Signature valid."
  }
};

function ContractSignature() {
  const navigate = useNavigate();
  const [width, setWidth] = React.useState<number>(0);
  const [documentWidth, setDocumentWidth] = React.useState<number>(0);
  const ref = React.useRef<HTMLDivElement>(null);
  const [signature, setSignature] = React.useState<string>();
  const [initials, setInitials] = React.useState<string>();
  const [signature2, setSignature2] = React.useState<string>();
  const [signatureIsValid, setSignatureIsValid] = React.useState<boolean>(false);
  const [initialsIsValid, setInitialsIsValid] = React.useState<boolean>(false);
  const [signature2IsValid, setSignature2IsValid] = React.useState<boolean>(false);
  const [agreementAccepted1, setAgreementAccepted1] = React.useState<boolean>(false);
  const [agreementAccepted2, setAgreementAccepted2] = React.useState<boolean>(false);
  const { state, dispatch } = React.useContext(DashboardsContext);
  const iframeRef = React.useRef<HTMLDivElement | null>(null);
  const [isSigning, setSigning] = React.useState(false);

  React.useEffect(() => {
    if (!iframeRef.current) return;
    const resizeObserver = new ResizeObserver(event => {
      event.forEach(item => {
        const height = item.contentRect.height + 44 + 44;

        postMessageSender({
          status: 'size',
          data: {
            height: height,
          },
        });
      });
    });

    resizeObserver.observe(iframeRef.current);
    return () => {
      resizeObserver.disconnect();
    }; // clean up
  }, [iframeRef]);

  React.useEffect(() => {
    console.log('review document version 4.0.0 loaded.');
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setWidth(ref.current ? (ref.current.offsetWidth > 620 ? 620 : ref.current.offsetWidth) : 0);
    setDocumentWidth(ref.current ? ref.current.offsetWidth : 0);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(ref.current ? (ref.current.offsetWidth > 620 ? 620 : ref.current.offsetWidth) : 0);
      setDocumentWidth(ref.current ? ref.current.offsetWidth : 0);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const onAgreeChange1 = (value: boolean | undefined) => {
    if (value !== undefined) {
      setAgreementAccepted1(value);
    }
  };

  const onAgreeChange2 = (value: boolean | undefined) => {
    if (value !== undefined) {
      setAgreementAccepted2(value);
    }
  };

  const onSignatureChange = (value: string | undefined) => {
    setSignature(value);
  };

  const onInitialsChange = (value: string | undefined) => {
    setInitials(value);
  };

  const onSignature2Change = (value: string | undefined) => {
    setSignature2(value);
  };

  const onAgreeAndSign = async () => {
    if (state.token !== undefined && state.loanId !== undefined) {
      postMessageSender({
        status: 'marketing',
        payload: {
          status: 'track',
          data: {
            event: 'sign_contract_submitted',
          },
        },
      });

      setSigning(true);
      const signRes = await sign(state.token, {
        initial: initials ?? signature ?? '',
        loanId: state.loanId,
        signature1: signature ?? '',
        signature2: signature2 ?? signature ?? '',
      });

      if (signRes.result) {

        postMessageSender({
          status: 'marketing',
          payload: {
            status: 'track',
            data: {
              event: 'sign_contract_completed',
              properties: {
                reloan: state.isReloan,
                referralProgram: state.referralAmbassadorName && state.referralAmbassadorName.length > 0 ? true : false,
                pagetype: "purchase",
                loanAmount: `${state.totalAmountOfPayments ?? ''}`
              },
            },
          },
        });

        if (!state.isReloan) {
          postMessageSender({
            status: 'marketing',
            payload: {
              status: 'track',
              data: {
                event: 'new_user_sign_contract_completed',
                properties: {
                  referralProgram: state.referralAmbassadorName && state.referralAmbassadorName.length > 0 ? true : false,
                  pagetype: "purchase"
                },
              },
            },
          });
        } else {
          postMessageSender({
            status: 'marketing',
            payload: {
              status: 'track',
              data: {
                event: 'reloan_sign_contract_completed',
                properties: {
                  referralProgram: state.referralAmbassadorName && state.referralAmbassadorName.length > 0 ? true : false,
                  pagetype: "purchase"
                },
              },
            },
          });
        }

        postMessageSender({
          status: 'marketing',
          payload: {
            status: 'track',
            data: {
              event: 'purchase',
              properties: {
                amount: state.loanDetails,
                nbPayments: state.repayments,
                province: state.provinceId,
              },
            },
          },
        });

        if (state.changeableLastDate) {
          const paymentDate = await getPaymentDate(state.token, state.loanId);
          if (paymentDate) {
            dispatch({type: 'setPaymentDate', payload: {paymentDate: paymentDate}})
            dispatch({type: 'setSignData', payload: signRes.result})
            goToView(navigate, AJUST_YOUR_FINAL_PAYMENT_DATE);  
          } else {
            console.log('Failed to get payment date but doing redirection to success signing in 500ms.');
            setTimeout(() => {
              console.log('Failed to get payment date but doing redirection to success signing timeout.', signRes.result);
              if (signRes.result) {
                postMessageSender({ status: 'success', data: signRes.result });
              }
            }, 500);
          }
        } else {
          console.log('success signing redirection in 500ms.');
          setTimeout(() => {
            console.log('success signing timeout.', signRes.result);
            if (signRes.result) {
              postMessageSender({ status: 'success', data: signRes.result });
            }
          }, 500);  
        }

      } else {
        setSigning(false);
        try {
          console.error(signRes?.error ?? 'contract-signature error');
        } catch (error) {
          console.error(error ?? 'try catch contract-signature error');          
        }
        postMessageSender({
          status: 'error',
          data: signRes.error ?? {
            code: 0,
            message: 'Unknown error',
          },
        });
      }
    } else {
      console.warn('no data.', state.token, state.loanId, signature, initials);
    }
  };

  React.useEffect(() => {
    postMessageSender({
      status: 'marketing',
      payload: {
        status: 'screen',
        data: {
          categoryName: 'Dashboard',
          pathName: 'sign_contract_loaded',
        },
      },
    });
  }, []);

  const disabled = React.useMemo(() => {
    if (state.provinceId !== 2 && state.provinceId !== 5) { // NOT BC AND NB
      if (!signatureIsValid || !signature2IsValid) {
        return true;
      }
      return false;
    } else {
      if (!signatureIsValid || !initialsIsValid || !signature2IsValid) {
        return true;
      }
    }
    return false;
  }, [signatureIsValid, initialsIsValid, signature2IsValid, state]);

  const onDisable = () => {
    if (state.provinceId !== 2 && state.provinceId !== 5) { // NOT BC AND NB
      if (!signatureIsValid) {
        document.querySelector("#signature1")?.scrollIntoView()
        return;
      }
      if (!signature2IsValid) {
        document.querySelector("#signature2")?.scrollIntoView()
      }
      return;
    } else {
      if (!signatureIsValid) {
        document.querySelector("#signature1")?.scrollIntoView()
        return;
      }
      if (!initialsIsValid) {
        document.querySelector("#initials")?.scrollIntoView()
        return;
      }
      if (!signature2IsValid) {
        document.querySelector("#signature2")?.scrollIntoView()
        return;
      }
    }
  }

  return (
    <div ref={iframeRef}>
      <h1 className="main-title-caption mb-[30px] sm:hidden">
        Sign agreement
      </h1>
      <div className='hidden sm:sticky sm:top-0 bg-[#FFFFFF] sm:mb-[11px] sm:grid grid-cols-12 items-center py-[11px] z-[20]'>
        <div className='col-span-1 p-[8px]' onClick={() => goToView(navigate, '/') }>
          <img alt="back" src={backMenu} rel='preload' />
        </div>
        <h2 className="title-caption text-center col-span-10">
        Sign agreement
        </h2>
        <div className='flex justify-end col-span-1 p-[8px]'>
          <img alt='contact-us' src={info} rel='preload' onClick={() => postMessageSender({status: 'contact-us', data: {message: 'Contact us mobile'}})} />
          </div>
      </div>
      <div className="flex flex-row sm:px-[8px]">
        <div ref={ref} id="content" className="w-[810px] sm:w-[100%] flex flex-col">
          <div className="">
            <p className="caption-light mb-[8px]">
              Please take a moment to review and digitally sign the documents below.
            </p>
            <p className="caption-light">
              A copy of this agreement will be sent to your email.
            </p>
          </div>
          <h3 className="subtitle-caption my-[33px]">Contract</h3>
          {state.loanContract?.content && <PDFViewer width={documentWidth} document={state.loanContract} />}
          <SignatureInput
            id="signature1"
            error=""
            height={197}
            width={width}
            label="Sign here"
            onChange={onSignatureChange}
            className="mt-[33px] sm:mt-[10px] mb-[0px]"
            isValid={(isValid: boolean) => setSignatureIsValid(isValid)}
            configStatus={configStatusSignature}
          />
          {state.provinceId === 2 || state.provinceId === 5 ? <SignatureInput  // BC AND NB
            error=""
            id="initials"
            height={197}
            width={width === 620 ? 311 : width}
            label="Initials"
            onChange={onInitialsChange}
            className="mt-[11px] mb-[0px]"
            isValid={(isValid: boolean) => setInitialsIsValid(isValid)}
            configStatus={configStatusSignature}
          /> : null}
          <h3 className="subtitle-caption my-[33px]">Pre-authorized agreement</h3>
          {state.padContract?.content && <PDFViewer width={documentWidth} document={state.padContract} />}
          <SignatureInput
            error=""
            id="signature2"
            height={197}
            width={width}
            label="Sign here"
            onChange={onSignature2Change}
            className="mt-[22px] sm:mt-[10px] mb-[0px]"
            isValid={(isValid: boolean) => setSignature2IsValid(isValid)}
            configStatus={configStatusSignature}
          />
          <p className="caption-light my-[33px]">
            <span className="font-600">Signatory:</span>{' '}
            {(state.firstName ?? 'John') + ' ' + (state.lastName ?? 'Doe')}
          </p>
          <div className="w-[620px] sm:w-full flex flex-col">
            <CheckboxInput
              disabled={disabled}
              value={agreementAccepted1}
              onChange={onAgreeChange1}
              className="mb-[16px] !mt-[0px]"
            >
              <p className='caption'>
                I confirm that I am the person for whom this documentation was intended. 
                By creating a signature in the boxes above I agree that the signature (and initials) 
                will be the electronic representation of my signature (and initials).
              </p>
            </CheckboxInput>
            <CheckboxInput
              disabled={disabled}
              value={agreementAccepted2}
              onChange={onAgreeChange2}
              className="mt-[0px] mb-[33px]"
            >
              <p className='caption'>
                I confirm that I have read and acknowledged the content of the agreements.
              </p>
            </CheckboxInput>
            <Button
              disabled={!agreementAccepted1 || !agreementAccepted2 || disabled || isSigning}
              onDisable={onDisable}
              loading={isSigning}
              appearance="primary"
              className="w-[100%] !mb-[8px]"
              size="large"
              onClick={onAgreeAndSign}
            >
              I agree and sign
            </Button>
            {state.padContract && state.loanContract && (
              <DocumentsView loading={isSigning} loanContract={state.loanContract} loanPad={state.padContract} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractSignature;
